<template>
    <div>
		<div class="row">
			<button v-if="seasonMareStallion.horse" v-e-online-only class="btn rounded-pill ml-3 mr-2 mb-2 btn-secondary" @click="load_pdf_suivi">
				<span>{{ $t("gynecologie.imprimer_suivi") }} </span>
				<font-awesome-icon :icon="['fal', 'file-spreadsheet']" />
			</button>
            <a
                v-e-online-only
                v-if="horse.horse_sire && !mask_ifce"
                class="btn btn-secondary rounded-pill mr-2 mb-2"
                :href="`https://infochevaux.ifce.fr/externe/${horse.horse_sire}`"
                target="_blank"
            >
                {{ $t('horse.voir_fiche_ifce') }}
                <font-awesome-icon :icon="['fal', 'external-link-square-alt']" />
            </a>
            <button class="btn rounded-pill mr-2 mb-2 btn-secondary" @click="load_info_contact">
                <span>{{ $t("gynecologie.info_contact.title") }} </span>
                <font-awesome-icon :icon="['fal', 'user-tie']" />
            </button>
            <button v-if="seasonMare.horse && !seasonMareStallion.horse" class="btn rounded-pill mr-2 mb-2 btn-secondary" @click="delete_from_season">
                <span>{{ $t("gynecologie.delete_from_season") }} </span>
                <font-awesome-icon v-if="processing_season_delete" :icon="['fal', 'spinner']" spin /><font-awesome-icon v-else :icon="['fal', 'times']" />
            </button>
		</div>
		<div class="float-right">
			<SeasonInput v-model="seasonSelected" @change="changeSeason"/>
            <button class="btn rounded-pill mt-2 btn-secondary" @click="load_dps_ws" v-show="horse && horse.horse_sire">
                <span>{{ $t('gynecologie.titre_dps_saillie_ws') }}</span>
            </button>
		</div>
		<div v-if="horse && need_show_horsename" class="row d-flex justify-content-between">
			<div class="col-sm-6 text-left">
				<router-link :to="{ name: 'horseFiche', params: { horse_id: horse.horse_id }}">
					<font-awesome-icon :icon="['fas', 'horse-head']" /> {{ horse.horse_nom }}
				</router-link>
			</div>
		</div>

        <LoadingSpinner class="col-12" v-if="processing_seasonmare" />
        <template v-else>
    		<div v-if="Object.keys(seasonMare).length > 0">
    			<div class="row mt-3">
    				<div class="col-lg-6 d-flex flex-column">
    					<h3 class='red'>{{ $t("gynecologie.commentaire_saison") }}</h3>
    					<div class="box flex-stretch">
    						<TextAreaInput :value="seasonMare.seasonmare_commentary" />
    					</div>
    				</div>
    				<div class="col-lg-6 d-flex flex-column">
    					<h3 class='red'>{{ $t("gynecologie.etalon_en_cours") }}</h3>
    					<div class="box flex-stretch">
    						<div v-if="Object.keys(seasonMareStallion).length > 0">
    							<router-link :to="{ name: 'HorseFicheInfo', params: { horse_id: seasonMareStallion.horse.horse_id }}">
    								<font-awesome-icon :icon="['fas', 'horse-head']" />

    								<span v-if="seasonMareStallion.seasonmarestallion_porteuse === seasonMare.seasonmare_id">
    									{{ seasonMareStallion.mare.horse.horse_nom }} x {{ seasonMareStallion.horse.horse_nom }}
    								</span>
    								<span v-else>
    									{{ seasonMareStallion.horse.horse_nom }}
    								</span>
    							</router-link>
    							<div v-for="(contact,index_c) in seasonMareStallion.contact" :key="'contact_'+index_c">
    								<div >
    									<hr class="my-2">
    									<div class="row">
    										<div class="col">
    											{{ contact.contact_firstname }} {{ contact.contact_lastname }}
    										</div>
    										<div class="col-auto ml-auto">
    											<div v-for="(phone,index_p) in contact.phones" :key="'phone_'+index_p">
    													<div >
    													<a v-bind-href="'mailto:'+phone.phone_indicatif+phone.phone_numero">{{ phone.phone_indicatif }} {{ phone.phone_numero }}</a>                                                        
    												</div>
    											</div>
    										</div>
    									</div>
    								</div>
    							</div>
    						</div>
    						<div v-else>
    							<label>{{ $t("gynecologie.aucun_etalon_en_cours") }}</label><br/>
    							<div class="btn btn-primary" @click="goToHistory(true)">{{ $t("gynecologie.ajouter_season_mare_stallion") }}</div>
    						</div>
    					</div>
    				</div>
    			</div>
    			<div class="row mt-3">
    				<div class="col-lg-9 d-flex flex-column">
                        <div v-if="seasonMareStallion">
                            <span>
                                <h3 class="d-inline">{{ $t("gynecologie.mare_status") }} : </h3>
                                <h4 class="d-inline">{{ seasonMareStallion.seasonmarestallion_status }}</h4>
                            </span>
                            <span v-if="previous_season_status">
                                <br />
                                <h6 class="d-inline">{{ $t("gynecologie.mare_previous_status") }} : {{ previous_season_status.status }}
                                    <router-link v-if="previous_season_status.horse_nom && previous_season_status.horse_id" :to="{ name: 'horseFiche', params: { horse_id: previous_season_status.horse_id }}">
                                        ({{ previous_season_status.horse_nom }})
                                    </router-link>
                                </h6>
                            </span>
                        </div>

    					<b-form-checkbox id="seasonmare_sanitary" class="mt-4" v-model="seasonMare.seasonmare_sanitary" @input="save_sanitary"><label for="seasonmare_sanitary">{{ $t("gynecologie.sanitaire_complet") }}</label></b-form-checkbox>
    				</div>
    				<div class="text-right col-lg-3">
    					<span class="btn btn-link" @click="goToHistory()">{{ $t("gynecologie.voir_historique") }} <font-awesome-icon :icon="['fal', 'long-arrow-right']" /></span><br/>
    					<span class="btn btn-link" @click="goToEmbryons()">{{ $t("gynecologie.voir_embryon") }} <font-awesome-icon :icon="['fal', 'long-arrow-right']" /></span>
    				</div>
    			</div>
    		</div>
    		<div v-else-if="seasonSelected && !horseFicheReadOnly">
    			<div class="box my-3">
    				<label>{{ $t("gynecologie.ajouter_jument_dans_saison") }}</label><br/>
    				<b-button variant="primary" :disabled="isAddingHorse" @click="addHorseSeason">{{ $t('global.oui') }}</b-button>
    			</div>
            </div>
        </template>

        <div v-if="scheduled_saillies && scheduled_saillies.length > 0" class="mt-1">
            {{ $t("gynecologie.saillie_reservee") }} 
            <span v-if="scheduled_saillies[0] && scheduled_saillies[0].stallion_nom">
                <router-link v-if="scheduled_saillies[0].planning_type == 'IA'" :to="{ name: 'planningIABeta', params: {planning_date: scheduled_saillies[0].planning_date}}">
                    {{ scheduled_saillies[0].planning_date_formatted }} 
                </router-link>
                <router-link v-else :to="{ name: 'planningMainBeta', params: {planning_date: scheduled_saillies[0].planning_date}}">
                    {{ scheduled_saillies[0].planning_date_formatted }} 
                </router-link>
                {{' ' + $t("gynecologie.at") + ' ' + scheduled_saillies[0].planning_start + ' ' + $t("gynecologie.avec") }}
                <router-link :to="{ name: 'horseFiche', params: { horse_id: scheduled_saillies[0].stallion_id }}">
                    {{ scheduled_saillies[0].stallion_nom }}
                </router-link>
            </span>
            <span v-if="scheduled_saillies[1] && scheduled_saillies[1].stallion_nom">, 
                <router-link v-if="scheduled_saillies[1].planning_type == 'IA'" :to="{ name: 'planningIABeta', params: {planning_date: scheduled_saillies[1].planning_date}}">
                    {{ scheduled_saillies[1].planning_date_formatted }} 
                </router-link>
                <router-link v-else :to="{ name: 'planningMainBeta', params: {planning_date: scheduled_saillies[1].planning_date}}">
                    {{ scheduled_saillies[1].planning_date_formatted }} 
                </router-link>
                {{' ' + $t("gynecologie.at") + ' ' + scheduled_saillies[1].planning_start + ' ' + $t("gynecologie.avec") }}
                <router-link :to="{ name: 'horseFiche', params: { horse_id: scheduled_saillies[1].stallion_id }}">
                    {{ scheduled_saillies[1].stallion_nom }}
                </router-link>
            </span>
        </div>

		<div class="mt-4">
			<Actes
				ref="ActSummary"
				:horse_id="horse.horse_id"
				:actual_season_mare_stallion="seasonMareStallion"
			/>
		</div>

        <b-modal ref="modalDps" hide-footer>
			<template v-slot:modal-title>
				{{ $t('gynecologie.titre_dps_saillie_ws') }}
			</template>

			<div v-if="!modal_dps_loaded">
				<LoadingSpinner class="col-12" />
			</div>

			<div v-if="modal_dps_loaded">
                <template v-if="!dps.length">
                    {{ $t('gynecologie.aucune_donnee_dps_saillie_ws') }}
                 </template>
                 <template v-else>
                    <div v-if="error_dps" class="alert alert-warning">
                        {{ $t('gynecologie.erreur_dps_saillie_ws') }}
                    </div>

                    <WarnAlert messageI18n="monte.dps_creation_acte" />

                    <div class="box my-3" v-for="data in dps" v-bind:key="data.monteSaillie">
                        {{ $t('horse.etalon') }} : <b>{{ data.etalonNom }}</b>
                        <br>
                        {{ $t('monte.porteuse') }} : <b>{{ data.porteuseNom }}</b>
                        <br>
                        {{ $t('monte.date_premier_saut') }} : <b>{{ data.monteDatePremierSaut_format }}</b>
                        <br>
                        {{ $t('compte.datenaissance') }} : <b>{{ data.produitDateNaiss_format }}</b>
                        <br>
                        {{ $t('horse.nom_naissance') }} : <b>{{ data.produitNom }}</b>
                        <br>
                        {{ $t('horse.nom_retenue') }} : <b>{{ data.nomRetenu }}</b>

                        <e-select
                            v-model="data.acte_type"
                            id="actestype_id"
                            track-by="actestype_id"
                            label="actestype_label"
                            :placeholder="$t('acte.selectionnez_acte')"
                            :selectedLabel="$t('global.selected_label')"
                            :options="actes_types"
                            :searchable="true"
                            :allow-empty="false"
                            :show-labels="false"
                            :group-select="false"
                        >
                            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                        </e-select>

                        <div class="text-center mt-3">
                            <b-form-checkbox v-model="data.selected"></b-form-checkbox>
                        </div>
                    </div>
    
                    <div class="text-center mt-3">
                        <b-button variant="primary" :disabled="loading_add_dps" @click="add_dps()">
                            <font-awesome-icon v-if="loading_add_dps" :icon="['fas', 'spinner']" pulse />
                            {{ $t('global.ajouter') }}
                        </b-button>
                        <b-button class="ml-2" variant="secondary" :disabled="loading_add_dps_commentaire" @click="add_dps_commentaire()">
                            <font-awesome-icon v-if="loading_add_dps_commentaire" :icon="['fas', 'spinner']" pulse />
                            {{ $t('gynecologie.add_dps_commentaire') }}
                        </b-button>
                    </div>
                </template>
			</div>
		</b-modal>

        <ModalConfirm
            ref="confirm_season_delete"
            id="confirm_season_delete_c"
            :icon="['fal', 'check']"
            :icon_accept="['fal', 'level-down']"
            :text_title="$t('modal.horse.validation_season_delete')"
            :text_question="$t('modal.horse.text_validation_season_delete')"
            :text_button_ok="$t('modal.horse.desassocier')"
            :text_button_cancel="$t('modal.general.annuler')"
            :callback_validation="confirmSwitchSaveSeasonDeleteModal"
            :callback_cancel="cancelSwitchSeasonDeleteModal"
            :processing="processing_season_delete"
        />
    </div>
</template>

<script type="text/javascript">
    import { EventBus } from 'EventBus'
    import TableAction from "@/mixins/TableAction.js"
    import Navigation from "@/mixins/Navigation.js"
    import HorseMixin from "@/mixins/Horse.js"
    import GynecologieMixin from "@/mixins/Gynecologie.js"
    import PlanningMonteMixin from "@/mixins/PlanningMonte.js"
    import ActeTypeMixin from "@/mixins/ActeType.js"
    import Shutter from "@/mixins/Shutter.js"
    import ShutterGyneco from "@/mixins/shutters-manager/Gyneco.js"
    import Common from '@/assets/js/common.js'

    export default {
        name: "GynecologieHorse",
        mixins: [
            TableAction, 
            Navigation, 
            HorseMixin, 
            GynecologieMixin, 
            ActeTypeMixin,
            PlanningMonteMixin,
            Shutter,
            ShutterGyneco
        ],
        props: {
            'horse': { type: Object, default: null },
            'horse_id': { type: String, default: '' },
            'need_show_horsename': { type: Boolean, default: false }
        },
        data () {
            return {
                seasonSelected: null,
                seasonMareHorse: null,
                seasonMare: {},
                seasonMareStallion: {},
                previous_season_status: null,
                events_tab: {
                    'TextAreaInput::startSave': (value) => {
                        this.saveCommentary(value)
                    },
                    'GynecologieHorse::reload': (openHistorique) => {
                        this.reload(openHistorique)
					},
                },
                reloadMethod: 'reloadActs',
				isAddingHorse: false,
				modal_dps_loaded: false,
                dps: null,
                loading_add_dps: false,
                error_dps: false,
                loading_add_dps_commentaire: false,
                mask_ifce: false,
                processing_seasonmare: false,
                actes_types: [],
                scheduled_saillies: [],
                processing_season_delete: false
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            init_component() {
                this.mask_ifce = this.getConfigIfce()
                // Récupération des saillies prévues dans les deux prochains jours
                this.loadMarePlanning()
            },
            dateWithoutTime(date) {
                return new Date(date.setHours(0,0,0,0))
            },
            async loadMarePlanning() {
                let mare_plannings = await this.getPlanningInfoByMare(this.horse.horse_id)
                let currentDate = this.dateWithoutTime(new Date())
                const millisec_in_day =  24 * 60 * 60 * 1000
                let limitDate = new Date(currentDate.getTime() + millisec_in_day * 2)

                // On ne garde que les créneaux réservé dans les 2 prochains jours
                mare_plannings = mare_plannings.filter((planning) => {
                    return new Date(planning.planning_date) >= currentDate && new Date(planning.planning_date) <= limitDate
                }
                )

                // On trie les créneaux par date
                mare_plannings.sort((a,b) => 
                    new Date(a.planning_date) - new Date(b.planning_date)
                )
                
                // On prend les deux premiers
                this.scheduled_saillies = mare_plannings.slice(0,2)
            },
            async loadSeasonMare() {
				if(!this.seasonSelected) return

				this.seasonMareHorse = await this.loadSeasonMareStallionBySeasonHorse(this.horse.horse_id, this.seasonSelected.id, false)
            },
            async reload(openHistorique) {
                await this.changeSeason()

                if(openHistorique) {
                    this.goToHistory()
                }
            },
            async changeSeason() {
                this.shutterPanel().close('gyneco-historique')

				if(this.seasonSelected && this.$refs.ActSummary) {
					this.$refs.ActSummary.reload(this.seasonSelected)
				}
                this.processing_seasonmare = true
                await this.loadSeasonMare()

                this.seasonMare = {}
                this.seasonMareStallion = {}
                this.previous_season_status = null
                
                if(this.seasonMareHorse && this.seasonSelected) {
                    // Récupération de la saison par défault
                    for(let i = 0; i < this.seasonMareHorse.length; i++) {
                        if(this.seasonSelected.id == this.seasonMareHorse[i].season.season_id) {
                            this.seasonMare = this.seasonMareHorse[i]

                            // Récupération du cheval par défault
                            let seasonmarestallion = this.seasonMare.seasonmarestallion
                            if(seasonmarestallion) {
                                for(let y = 0; y < seasonmarestallion.length; y++) {
                                    if(seasonmarestallion[y].seasonmarestallion_default) {

                                        // Recuperation des contact
                                        let contact = []
                                        let horseContact = seasonmarestallion[y].horse.contact
                                        if(horseContact) {
                                            for (let u = 0; u < horseContact.length; u++) {

                                                // Récupération de la fonction "etalonnier"
                                                let contactFonction = horseContact[u].fonctions
                                                if(contactFonction) {
                                                    for (var o = 0; o < contactFonction.length; o++) {
                                                        if(contactFonction[o].fonction_code == "ETAL") {
                                                            contact.push(horseContact[u].contact)
                                                        }
                                                    }
                                                }
                                            }
                                        }

                                        seasonmarestallion[y].contact = contact
                                        this.seasonMareStallion = seasonmarestallion[y]
                                    }
                                }
                            }
                        }
                    }

                    if(!this.seasonMareStallion.seasonmarestallion_id && this.seasonMare.seasonmarestallion && this.seasonMare.seasonmarestallion.length > 0) {
                        let contact = []
                        let horseContact = this.seasonMare.seasonmarestallion[0].horse.contact
                        if(horseContact) {
                            for (let u = 0; u < horseContact.length; u++) {

                                // Récupération de la fonction "etalonnier"
                                let contactFonction = horseContact[u].fonctions
                                if(contactFonction) {
                                    for (var p = 0; p < contactFonction.length; p++) {
                                        if(contactFonction[p].fonction_code == "ETAL") {
                                            contact.push(horseContact[u].contact)
                                        }
                                    }
                                }
                            }
                        }

                        this.seasonMare.seasonmarestallion[0].contact = contact
                        this.seasonMareStallion = this.seasonMare.seasonmarestallion[0]
                    }

                    // Récupération du status de la saison actuelle
                    if(this.seasonMareStallion && this.seasonMareStallion.seasonmarestallion_status) {
                        this.seasonMareStallion.seasonmarestallion_status = Common.translateSeasonMareStatus(this.seasonMareStallion.seasonmarestallion_status)
                    }

                    // Récupération du status de la saison précédente
                    const previous_season = await this.getPreviousSeasonFromSeason(this.seasonSelected.id)
                    if(previous_season) {
                        let previous_season_status = await this.loadSeasonMareStallionStatus(previous_season)
                        if(previous_season_status) {
                            this.previous_season_status = previous_season_status
                            this.previous_season_status.status = previous_season_status.status ? Common.translateSeasonMareStatus(previous_season_status.status) : null
                        }                        
                    }

                }
                this.processing_seasonmare = false
            },
            async loadSeasonMareStallionStatus(season) {
				let seasonMareHorse = await this.loadSeasonMareStallionBySeasonHorse(this.horse.horse_id, season.season_id)

				if(seasonMareHorse.length === 0) return null

				let seasonMare = {}
                let seasonMareStallion = {}
                if(seasonMareHorse && season) {
                    for(let i = 0; i < seasonMareHorse.length; i++) {
                        if(season.season_id == seasonMareHorse[i].season.season_id) {
                            seasonMare = seasonMareHorse[i]
                            let seasonmarestallion = seasonMare.seasonmarestallion
                            if(seasonmarestallion) {
                                for(let y = 0; y < seasonmarestallion.length; y++) {
                                	// on prend l'état physio de la jument si couple ou si trouple avec jument en porteuse
                                    if(seasonmarestallion[y].seasonmarestallion_default && ((seasonmarestallion[y].mare.horse.horse_id == this.horse.horse_id && seasonmarestallion[y].porteuse == null) || (seasonmarestallion[y].porteuse && seasonmarestallion[y].porteuse.horse.horse_id == this.horse.horse_id))) {
                                        seasonMareStallion = seasonmarestallion[y]
                                        continue;
                                    }
                                }
                            }
                        }
                    }
				}
				if (seasonMareStallion) {
					if (seasonMareStallion.porteuse) {
						return {
							"status": seasonMareStallion.seasonmarestallion_status,
							"horse_nom": seasonMareStallion.horse.horse_nom,
							"horse_id": seasonMareStallion.horse.horse_id,
							"donneuse_nom": seasonMareStallion.mare.horse.horse_nom,
							"donneuse_id": seasonMareStallion.mare.horse.horse_id,
						}
	
					} 
					if (seasonMareStallion.horse) {
						return {
							"status": seasonMareStallion.seasonmarestallion_status,
							"horse_nom": seasonMareStallion.horse.horse_nom,
							"horse_id": seasonMareStallion.horse.horse_id,
						}
					}
				}
				return null
			},
            async addHorseSeason() {
				this.isAddingHorse = true

                const result = await this.addHorseSeasonMare(
                    this.seasonSelected.id,
                    this.horse.horse_id,
                    ""
                );

                if(result) {
                    await this.reload()
                }

				this.isAddingHorse = false
            },
            async saveCommentary(value) {
                const result = await this.editSeasonMare(
                    this.seasonMare.seasonmare_id,
                    {seasonmare_commentary: value}
                );

                if(result) {
                    this.seasonMare.seasonmare_commentary = value
                    EventBus.$emit('TextAreaInput::stopSave', true)
                } else {
                    EventBus.$emit('TextAreaInput::stopSave', false)
                }
            },
            goToHistory(goToAddHorseStallion=false) {
                const shown = this.shutterPanel().isOpen('gyneco-historique')
				const shown_embryons = this.shutterPanel().isOpen('gyneco-embryons')

                if(shown) {
                    this.shutterPanel().close('gyneco-historique')
                }
				else if(shown_embryons) {
                    this.shutterPanel().close('gyneco-embryons')
                }
                this.setupHorseGynecoHistory(this.seasonSelected.id, this.horse.horse_id, goToAddHorseStallion)
            },
			goToEmbryons() {
                const shown = this.shutterPanel().isOpen('gyneco-embryons')
				const shown_historique = this.shutterPanel().isOpen('gyneco-historique')

                if(shown) {
                    this.shutterPanel().close('gyneco-embryons')
                }
				else if(shown_historique) {
					this.shutterPanel().close('gyneco-historique')
				}
                this.setupHorseGynecoEmbryons(this.seasonSelected.id, this.horse.horse_id)
            },
            reloadActs() {
                const reloadActMethod = this.$refs.ActSummary.reloadMethod
                this.$refs.ActSummary[reloadActMethod]()
			},
            async save_sanitary(bool) {
                const res = await this.saveSanitary(this.seasonMare.seasonmare_id, bool)
                if(res == 1) {
                    this.successToast("toast.info_modif_succes")
                } else {
                    this.failureToast()
                }
            },
			load_pdf_suivi() {
				this.generatePdfSuiviGyneco(this.horse.horse_id, this.seasonSelected.id, this.seasonMareStallion.horse.horse_id)
			},
            load_info_contact() {
                this.setupHorseInfoContact(this.horse.horse_id)
            },
            cancelSwitchSeasonDeleteModal() {
				this.$refs.confirm_season_delete.closeModal()
			},
            delete_from_season(){
                this.$refs.confirm_season_delete.openModal()
            },
            async confirmSwitchSaveSeasonDeleteModal() {
                this.processing_season_delete = true
                await this.deleteSeasonMare(this.seasonMare.seasonmare_id)
                this.$refs.confirm_season_delete.closeModal()
                this.successToast('toast.info_save_succes')
                this.processing_season_delete = false
                await this.changeSeason()
            },
            async load_dps_ws() {
                this.modal_dps_loaded = false
                this.$refs['modalDps'].show()
                this.actes_types = await this.getActeTypeSaillie()

                let dps = await this.getInfoSailliesWs(this.horse.horse_id, this.seasonSelected.id)
                for(let i = 0; i < dps.length; i++) {
                    dps[i].selected = true
                    dps[i].acte_type = this.actes_types[0]
                }

                this.dps = dps
                this.modal_dps_loaded = true
            },
            async add_dps() {
                this.loading_add_dps = true;

                const checked = this.dps
                    .filter(_dps => _dps.selected);

                let data = []
                for(let i = 0; i < checked.length; i++) {
                    data.push({
                        monteSaillie: checked[i].monteSaillie,
                        monteAnnee: checked[i].monteAnnee,
                        monteActeType: checked[i].acte_type.actestype_id
                    })
                }
                
                if(data) {
                    this.error_dps = false
                    const result = await this.AddInfoSailliesWs(this.horse.horse_id, data)
                    if(result) {
                        if(result.retour.erreur == false) {
                            await this.$sync.force(true)
                            await this.reload()
                            this.successToast()
                            this.$refs['modalDps'].hide()
                        } else {
                            this.error_dps = true
                        }
                    }
                }

                this.loading_add_dps = false;
            },
            async add_dps_commentaire() {
                this.loading_add_dps_commentaire = true;

                const checked = this.dps
                    .filter(_dps => _dps.selected);

                let data = this.seasonMare.seasonmare_commentary
                for(let i = 0; i < checked.length; i++) {
                    if(data) {
                        data += '\n\n'
                    }

                    data += this.getTrad('horse.etalon')+' : '+(checked[i].etalonNom ?? '')+'\n'+this.getTrad('monte.porteuse')+' : '+(checked[i].porteuseNom ?? '')+'\n'+this.getTrad('monte.date_premier_saut')+' : '+(checked[i].monteDatePremierSaut_format ?? '')+'\n'+this.getTrad('compte.datenaissance')+' : '+(checked[i].produitDateNaiss_format ?? '')+'\n'+(this.getTrad('horse.nom_naissance') ?? '')+' : '+(checked[i].produitNom ?? '')+'\n'+this.getTrad('horse.nom_retenue')+' : '+(checked[i].nomRetenu ?? '')+'\n'+this.getTrad('horse.type_monte')+' : '+this.getTrad('actes_type.'+checked[i].acte_type.actestype_code)+''
                }
                
                if(data) {
                    this.saveCommentary(data)
                }

                this.loading_add_dps_commentaire = false;
            }
        },
        computed: {
			horseFicheReadOnly() {
                return this.$store.state.horseFicheReadonly
            }
        },
        components: {
            LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
            CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
            TextAreaInput : () => import('GroomyRoot/components/Inputs/TextAreaInput'),
            SeasonInput : () => import('@/components/Inputs/SeasonInput'),
            Actes : () => import('@/components/Gynecologie/Actes'),
            WarnAlert : () => import('GroomyRoot/components/Alert/WarnAlert'),
            ModalConfirm : () => import('@/components/Modals/ModalConfirm')
        },
    }
</script>
